import React, {useState, useEffect} from "react";
import Sidebar from "../../components/Sidebar";
import {getUserListServ} from "../../services/user.services"
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import {useNavigate} from "react-router-dom"
import { useGlobalState } from "../../GlobalProvider";
function AddUser() {
  const { setGlobalState, globalState } = useGlobalState();
  const navigate = useNavigate()
  return (
    <div className="main_layout  bgBlack d-flex">
      {/* sidebar started */}
      <Sidebar selectedItem="User" />
      {/* sidebar ended */}

      {/* sectionLayout started */}
      <section className="section_layout"  style={{minWidth:"1250px", marginLeft :globalState?.isFillSidebarWidth100 ? "260px": "80px" }}>
      <div className="vh80 d-flex justify-content-center align-items-center">
                <h1>Add User Comming Soon</h1>
                <i className="fa fa-close ms-4 border p-2 rounded text-danger" onClick={()=>navigate("/driver-list")}></i>
            </div>
      </section>
      {/* sectionLayout ended */}
    </div>
  );
}

export default AddUser;
