import React from "react";
import Sidebar from "../../components/Sidebar";
import TopNav from "../../components/TopNav";
import TableNav from "../../components/TableNav";
import { useGlobalState } from "../../GlobalProvider";
function DriverQuickWithdraw() {
  const { setGlobalState, globalState } = useGlobalState();
  const navItems = [
    {
      name: "User Deposite",
      path: "/user-interac-deposite",
    },
    {
      name: "Driver Withdraw",
      path: "/driver-weekly-withdraw",
    },
    {
      name: "Switch",
      path: "/funds-switch",
    },
    
    {
      name: "Cancel Response",
      path: "/funds-cancel-response",
    },
  ];
  const tableNav = [
    {
      name: "Weekly Withdraw",
      path: "/driver-weekly-withdraw"
    },
    {
      name: "Quick Withdraw",
      path: "/driver-quick-withdraw",
    },
  ];
  return (
    <div className="main_layout  bgBlack d-flex">
      {/* sidebar started */}
      <Sidebar selectedItem="Funds Management"/>
      {/* sidebar ended */}
      
      {/* sectionLayout started */}
      <section className="section_layout" style={{ marginLeft :globalState?.isFillSidebarWidth100 ? "260px": "80px" }}>
        {/* top nav started  */}
        <TopNav
          navItems={navItems}
          navColor="#fff"
          navBg="#043B64"
          divideRowClass="col-xl-6 col-lg-6 col-md-12 col-12"
          selectedItem="Driver Withdraw"
          sectedNavBg="#fff"
          selectedNavColor="#030303"
        />
        {/* top nav ended  */}
        {/* table List started */}
        <div className="tableMain">
          <TableNav tableNav={tableNav} selectedItem="Quick Withdraw" sectedItemBg="#f2fbff" />
          <div className="tableBody py-2 px-4 borderRadius50All" style={{ background: "#f2fbff" }}>
            <div className="row mx-0 p-0 justify-content-around marginY35">
              <div className="col-lg-4  m-0 ">
                <div className="d-flex justify-content-between align-items-center whiteBtn">
                  <p className="mb-0">Total Quick Deposite</p>
                  <p className="mb-0">$4400</p>
                </div>
              </div>
              <div className="col-lg-4 m-0">
                <div className="d-flex justify-content-between align-items-center whiteBtn">
                  <p className="mb-0">Monthly Quick Deposite</p>
                  <p className="mb-0">$4400</p>
                </div>
              </div>
              <div className="col-lg-4 m-0 ">
                <div className="d-flex justify-content-between align-items-center whiteBtn">
                  <p className="mb-0">Today's Quick Deposite</p>
                  <p className="mb-0">$4400</p>
                </div>
              </div>
            </div>
            <div style={{ margin: "0px 10px"  }}>
              <table className="table">
                <thead >
                  <tr style={{ background: "#DCE4E7", }}>
                    <th scope="col" style={{ borderRadius: "8px 0px 0px 8px" }}>
                      Sr. No
                    </th>
                    <th scope="col">First Name</th>
                    <th scope="col">Last Name</th>
                    <th scope="col">Date</th>
                    <th scope="col">Time</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Status</th>
                    <th scope="col">Transection Proof</th>
                    <th scope="col" style={{ borderRadius: "0px 8px 8px 0px" }}>
                      Action
                    </th>
                  </tr>
                </thead>
                <div className="py-2"></div>
                <tbody >
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9]?.map((v, i) => {
                    return (
                      <tr>
                        <td scope="row">{i+1}</td>
                        <td>Madhu</td>
                        <td>Kashyap</td>
                        <td>04/10/2024</td>
                        <td>07:25 AM</td>
                        <td>$20</td>
                        <td>Accept</td>
                        <td >
                          <div className="d-flex justify-content-center iconDiv" >
                          <img src="https://cdn-icons-png.flaticon.com/128/159/159604.png"/>
                          <img src="https://cdn-icons-png.flaticon.com/128/724/724933.png"/>
                          </div>
                          
                        </td>
                        <td className="d-flex justify-content-center align-items-center">
                          <select >
                            <option>Pending</option>
                            <option>Accept</option>
                            <option>Reject</option>
                          </select>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {/* table List ended */}
      </section>
      {/* sectionLayout ended */}
    </div>
  );
}

export default DriverQuickWithdraw;
