import React, { useState , useEffect} from "react";
import Sidebar from "../../components/Sidebar";
import TopNav from "../../components/TopNav";
import { getGtChargeList, addGtChargeServ} from "../../services/priceAndCity.services";
import { toast } from "react-toastify";
import { useGlobalState } from "../../GlobalProvider";
function PricingGtCharges() {
  const { setGlobalState, globalState } = useGlobalState();
  const navItems = [
    {
      name: "Categories",
      path: "/pricing-categories",
    },
    {
      name: "Province",
      path: "/pricing-province",
    },
    {
      name: "Location",
      path: "/pricing-sharing-location",
    },
    {
      name: "Surges",
      path: "/pricing-sharing-surges",
    },
    {
      name: "Commission",
      path: "/pricing-commission",
    },
    {
      name: "G.T.Charges",
      path: "/pricing-gt-charges",
    },
    {
      name: "Interac Id",
      path: "/pricing-iterac-id",
    },
    {
      name: "Payout Info",
      path: "/pricing-payout-info",
    },
    {
      name: "Cancel",
      path: "/pricing-cancel",
    },
  ];
  const [gtFormData, setGtFormData]=useState({
    gt_charge:"",
    category_id:"1",
    is_editable:false
  });
  
  const handleGetCommissionListFunc = async ()=>{
    try {
      let response = await getGtChargeList({category_id:1});
      if (response?.data?.statusCode == "200") {
        setGtFormData(response?.data?.data[0]) 
        console.log(response?.data?.data[0])
      }
    } catch (error) {
      
    }
  }
  useEffect(()=>{
    handleGetCommissionListFunc()
  }, []);

  const handleGtChargeAddFunc =async ()=>{
    try {
     let response = await addGtChargeServ(gtFormData) 
     if (response?.data?.statusCode == "200") {
      toast.success(response?.data?.message)
    }
    } catch (error) {
      toast.success("Internal Server Error")
    }
  }
  return (
    <div className="main_layout  bgBlack d-flex">
      {/* sidebar started */}
      <Sidebar selectedItem="Pricing & Cities" />
      {/* sidebar ended */}

      {/* sectionLayout started */}
      <section className="section_layout" style={{ marginLeft :globalState?.isFillSidebarWidth100 ? "260px": "80px" }}>
        {/* top nav started  */}
        <TopNav
          navItems={navItems}
          navColor="#fff"
          navBg="#18BEBC"
          divideRowClass="col-xl-6 col-lg-6 col-md-6 col-6"
          selectedItem="G.T.Charges"
          sectedNavBg="#fff"
          selectedNavColor="#030303"
          isItemMoreThen8={true}
        />
        {/* top nav ended  */}

        <div
          className="vh80 d-flex  justify-content-center align-items-center gtMainDiv"
          style={{ background: "#f8f4f9", borderRadius: "14px",  }}
        >
          <div style={{width:"700px"}}>
            <h1 className="text-center">Gateway Terminal Charges</h1>
            <div className="d-flex justify-content-between align-items-center" style={{margin:"100px 0px"}}>
              <p>Gateway Terminal Charges</p>
              <div className="d-flex justify-content-between align-items-center commissionInput" style={{width:"60%"}}>
                <input className="" placeholder="Enter Here" value={gtFormData?.gt_charge} onChange={(e)=>setGtFormData({...gtFormData, gt_charge:e.target.value})}/>
                <p className="mb-0">%</p>
              </div>
            </div>
            <div className="commissionBtnGroup d-flex justify-content-between">
              <button className="" style={{ width: "45%" }} onClick={()=>{setGtFormData({...gtFormData, is_editable:true}); toast.success("Start Editing the form")}}>
                Edit
              </button>

              <button className="" style={{ background: "#139F01", width: "45%", opacity:gtFormData.is_editable ? "1": "0.5" }} onClick={gtFormData.is_editable && handleGtChargeAddFunc }>
                Save
              </button>
            </div>
          </div>
        </div>
      </section>
      {/* sectionLayout ended */}
    </div>
  );
}

export default PricingGtCharges;
