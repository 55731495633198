import axios from "axios";

// Define your API base URL
const BASE_URL = "https://api.faizah.in/api/";
// const BASE_URL = "http://206.189.135.17/api/";

const access_token = localStorage.getItem("access_token");

const config = {
  headers: {
    "Content-Type": "multipart/form-data",
    Accept: "application/json",
    Authorization: `Bearer ${access_token}`,
  },
};
const getConfig = () => {
  return {
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
      Authorization: `Bearer ${JSON.parse(localStorage.getItem("access_token"))}`,
    },
  };
};
export const addProvinceServ = async (formData) => {
  try {
    const response = await axios.post(BASE_URL + "add-province", formData, getConfig());
    return response;
  } catch (error) {
    // Handle error (e.g., log or throw an error)
    console.error("Error fetching data:", error);
    throw error;
  }
};
export const listProvinceServ = async () => {
    try {
      const response = await axios.get(BASE_URL + "list-province", getConfig());
      return response;
    } catch (error) {
      // Handle error (e.g., log or throw an error)
      console.error("Error fetching data:", error);
      throw error;
    }
};
export const deleteProvinceServ = async (id) => {
  try {
    const response = await axios.delete(BASE_URL + "delete-province/"+id, getConfig());
    return response;
  } catch (error) {
    // Handle error (e.g., log or throw an error)
    console.error("Error fetching data:", error);
    throw error;
  }
};
export const getCategoryServ = async () => {
    try {
      const response = await axios.get(BASE_URL + "list-ride-category", getConfig());
      return response;
    } catch (error) {
      // Handle error (e.g., log or throw an error)
      console.error("Error fetching data:", error);
      throw error;
    }
};
export const getCategoryUpdateServ = async (id, formData) => {
  try {
    const response = await axios.post(BASE_URL + "update-ride-category-status/"+id, formData, getConfig());
    return response;
  } catch (error) {
    // Handle error (e.g., log or throw an error)
    console.error("Error fetching data:", error);
    throw error;
  }
};
export const addSharingPriceServ = async (formData) => {
  try {
    const response = await axios.post(BASE_URL + "store-sharing-ride-price",formData, getConfig());
    return response;
  } catch (error) {
    // Handle error (e.g., log or throw an error)
    console.error("Error fetching data:", error);
    throw error;
  }
};
export const sharingLocationListServ = async (formData) => {
  try {
    const response = await axios.post(BASE_URL + "get-ride-price-list",formData, getConfig());
    return response;
  } catch (error) {
    // Handle error (e.g., log or throw an error)
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const getSurgesListServ = async (formData) => {
  try {
    const response = await axios.post(BASE_URL + "list-surges",formData, getConfig());
    return response;
  } catch (error) {
    // Handle error (e.g., log or throw an error)
    console.error("Error fetching data:", error);
    throw error;
  }
};
export const addSurgesServ = async (formData) => {
  try {
    const response = await axios.post(BASE_URL + "store-surge",formData, getConfig());
    return response;
  } catch (error) {
    // Handle error (e.g., log or throw an error)
    console.error("Error fetching data:", error);
    throw error;
  }
};
export const editSurgesServ = async (formData) => {
  try {
    const response = await axios.post(BASE_URL + "update-surge",formData, getConfig());
    return response;
  } catch (error) {
    // Handle error (e.g., log or throw an error)
    console.error("Error fetching data:", error);
    throw error;
  }
};
export const deleteSurgesServ = async (id) => {
  try {
    const response = await axios.delete(BASE_URL + "delete-surge/"+id, getConfig());
    return response;
  } catch (error) {
    // Handle error (e.g., log or throw an error)
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const addCommissionServ = async (formData) => {
  try {
    const response = await axios.post(BASE_URL + "store-commission",formData, getConfig());
    return response;
  } catch (error) {
    // Handle error (e.g., log or throw an error)
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const editCommissionServ = async (formData) => {
  try {
    const response = await axios.post(BASE_URL + "update-commission",formData, getConfig());
    return response;
  } catch (error) {
    // Handle error (e.g., log or throw an error)
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const getCommissionListServ = async () => {
  try {
    const response = await axios.post(BASE_URL + "list-commissions", {}, getConfig());
    return response;
  } catch (error) {
    // Handle error (e.g., log or throw an error)
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const getGtChargeList = async (formData) => {
  try {
    const response = await axios.post(BASE_URL + "list-gt-charge", formData, getConfig());
    return response;
  } catch (error) {
    // Handle error (e.g., log or throw an error)
    console.error("Error fetching data:", error);
    throw error;
  }
};
export const addGtChargeServ = async (formData) => {
  try {
    const response = await axios.post(BASE_URL + "store-gt-charge", formData, getConfig());
    return response;
  } catch (error) {
    // Handle error (e.g., log or throw an error)
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const getInteracIdChargeList = async (formData) => {
  try {
    const response = await axios.get(BASE_URL + "list-interac-e-id", getConfig());
    return response;
  } catch (error) {
    // Handle error (e.g., log or throw an error)
    console.error("Error fetching data:", error);
    throw error;
  }
};
export const addInteracIdChargeServ = async (formData) => {
  try {
    const response = await axios.post(BASE_URL + "store-interac-e-id", formData, getConfig());
    return response;
  } catch (error) {
    // Handle error (e.g., log or throw an error)
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const getPayoutServ = async () => {
  try {
    const response = await axios.get(BASE_URL + "list-payoutinfo", getConfig());
    return response;
  } catch (error) {
    // Handle error (e.g., log or throw an error)
    console.error("Error fetching data:", error);
    throw error;
  }
};
export const handleSubmitPayoutInfoServ = async (formData) => {
  try {
    const response = await axios.post(BASE_URL + "store-payoutinfo",formData, getConfig());
    return response;
  } catch (error) {
    // Handle error (e.g., log or throw an error)
    console.error("Error fetching data:", error);
    throw error;
  }
};