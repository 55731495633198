import axios from "axios";

// Define your API base URL
const BASE_URL = "https://api.faizah.in/api/";

const access_token = localStorage.getItem("access_token");

const config = {
  headers: {
    "Content-Type": "multipart/form-data",
    Accept: "application/json",
    Authorization: `Bearer ${access_token}`,
  },
};
const getConfig = () => {
  return {
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
      Authorization: `Bearer ${JSON.parse(localStorage.getItem("access_token"))}`,
    },
  };
};
export const getUserInteractETransferServ = async () => {
  try {
    const response = await axios.get(BASE_URL + "list-intrac-e-transfer", getConfig());
    return response;
  } catch (error) {
    // Handle error (e.g., log or throw an error)
    console.error("Error fetching data:", error);
    throw error;
  }
};
export const updateUserInteracStatusServ = async (formData) => {
  try {
    const response = await axios.post(BASE_URL + "update-intrac-e-transfer-status",formData, getConfig());
    return response;
  } catch (error) {
    // Handle error (e.g., log or throw an error)
    console.error("Error fetching data:", error);
    throw error;
  }
};
export const getUserQuickServ = async () => {
    try {
      const response = await axios.post(BASE_URL + "list-quick-deposit-payment",{}, getConfig());
      return response;
    } catch (error) {
      // Handle error (e.g., log or throw an error)
      console.error("Error fetching data:", error);
      throw error;
    }
};
export const getCategoryServ = async () => {
    try {
      const response = await axios.get(BASE_URL + "list-ride-category", getConfig());
      return response;
    } catch (error) {
      // Handle error (e.g., log or throw an error)
      console.error("Error fetching data:", error);
      throw error;
    }
};
