import React, { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar";
import TopNav from "../../components/TopNav";
import TableNav from "../../components/TableNav";
import { getUserInteractETransferServ, updateUserInteracStatusServ } from "../../services/fundsManagement.services";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { toast } from "react-toastify";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { useGlobalState } from "../../GlobalProvider";
function DriverRating() {
  const { setGlobalState, globalState } = useGlobalState();
  const params = useParams();
  const tableNav = [
    {
      name: "Profile",
      path: `/driver-profile/${params?.id}`,
    },
    {
      name: "Document",
      path: `/driver-document/${params?.id}`,
    },
    {
      name: "Account",
      path: `/driver-account/${params?.id}`,
    },
    {
      name: "Rating",
      path: `/driver-rating/${params?.id}`,
    },
    {
      name: "Transection History",
      path: `/driver-transection-history/${params?.id}`,
    },
  ];

  return (
    <div className="main_layout  bgBlack d-flex">
      {/* sidebar started */}
      <Sidebar selectedItem="Driver" />
      {/* sidebar ended */}

      {/* sectionLayout started */}
      <section className="section_layout" style={{ marginLeft :globalState?.isFillSidebarWidth100 ? "260px": "80px" }}>
        {/* top nav started  */}

        {/* top nav ended  */}
        {/* table List started */}
        <div className="">
          <TableNav tableNav={tableNav} selectedItem="Rating" sectedItemBg="#FDEEE7" />
          <div className="tableBody py-2 px-4 borderRadius50All" style={{ background: "#FDEEE7" }}>
            <div className=" p-5 px-4 my-4" style={{ borderRadius: "20px", background: "#fff" }}>
              <div className="row m-0 p-0">
                <div className="col-6 ">
                  <div className="ratigBox d-flex align-items-center justify-content-center">
                    <div className="ratingBlackBox shadow d-flex justify-content-center align-items-center">
                      <div>3.0</div>
                    </div>
                    <div className="d-flex justify-content-between starBox">
                      <i className="fa fa-star textSuccess"></i>
                      <i className="fa fa-star textSuccess"></i>
                      <i className="fa fa-star textSuccess"></i>
                      <i className="fa fa-star"></i>
                      <i className="fa fa-star"></i>
                    </div>
                  </div>

                  <div className="d-flex align-items-center my-5">
                    <div style={{ width: "20%" }} className="text-center">
                      5 Star
                    </div>
                    <div
                      className=""
                      style={{ width: "80%", background: "#F3F3F3", borderRadius: "8px", height: "30px" }}
                    >
                      <div style={{ height: "30px", background: "#139F01", width: "80%", borderRadius: "8px" }}></div>
                    </div>
                    <div style={{ width: "20%" }} className="text-center">
                      80%
                    </div>
                  </div>
                  <div className="d-flex align-items-center my-5">
                    <div style={{ width: "20%" }} className="text-center">
                      5 Star
                    </div>
                    <div
                      className=""
                      style={{ width: "80%", background: "#F3F3F3", borderRadius: "8px", height: "30px" }}
                    >
                      <div style={{ height: "30px", background: "#139F01", width: "70%", borderRadius: "8px" }}></div>
                    </div>
                    <div style={{ width: "20%" }} className="text-center">
                      70%
                    </div>
                  </div>
                  <div className="d-flex align-items-center my-5">
                    <div style={{ width: "20%" }} className="text-center">
                      5 Star
                    </div>
                    <div
                      className=""
                      style={{ width: "80%", background: "#F3F3F3", borderRadius: "8px", height: "30px" }}
                    >
                      <div style={{ height: "30px", background: "#139F01", width: "90%", borderRadius: "8px" }}></div>
                    </div>
                    <div style={{ width: "20%" }} className="text-center">
                      90%
                    </div>
                  </div>
                  <div className="d-flex align-items-center my-5">
                    <div style={{ width: "20%" }} className="text-center">
                      5 Star
                    </div>
                    <div
                      className=""
                      style={{ width: "80%", background: "#F3F3F3", borderRadius: "8px", height: "30px" }}
                    >
                      <div style={{ height: "30px", background: "#139F01", width: "50%", borderRadius: "8px" }}></div>
                    </div>
                    <div style={{ width: "20%" }} className="text-center">
                      50%
                    </div>
                  </div>
                  <div className="d-flex align-items-center my-5">
                    <div style={{ width: "20%" }} className="text-center">
                      5 Star
                    </div>
                    <div
                      className=""
                      style={{ width: "80%", background: "#F3F3F3", borderRadius: "8px", height: "30px" }}
                    >
                      <div style={{ height: "30px", background: "#139F01", width: "20%", borderRadius: "8px" }}></div>
                    </div>
                    <div style={{ width: "20%" }} className="text-center">
                      20%
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="ratigBox ">
                    <h4 className="text-center mb-4">Reviews</h4>
                    {[1, 2, 3, 4, 5]?.map((v, i) => {
                      return (
                        <div className="reviewBox d-flex align-items-center" style={{margin:"10px 0px"}}>
                          <div>
                            <img src="https://cdn-icons-png.flaticon.com/128/3687/3687416.png" />
                          </div>
                          <div className="w-100 ms-2">
                            <div className="d-flex justify-content-between w-100">
                              <div>
                                <i className="fa fa-star textSuccess"></i>
                                <i className="fa fa-star textSuccess"></i>
                                <i className="fa fa-star textSuccess"></i>
                                <i className="fa fa-star "></i>
                                <i className="fa fa-star "></i>
                                <span>Sandeep Sidhu</span>
                              </div>
                              <p className="mb-0">1 day ago</p>
                            </div>
                            <p className="mb-0">Great Driver</p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* table List ended */}
      </section>
      {/* sectionLayout ended */}
    </div>
  );
}

export default DriverRating;
