import React, { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar";
import TopNav from "../../components/TopNav";
import TableNav from "../../components/TableNav";
import { getDriverByIdServ, updateDriverServ } from "../../services/driver.services";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { toast } from "react-toastify";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { useGlobalState } from "../../GlobalProvider";
function DriverTransectionHistory() {
  const { setGlobalState, globalState } = useGlobalState();
  const [showSkelton, setShowSkelton] = useState(true);
  const params = useParams()
  const tableNav = [
    {
      name: "Profile",
      path: `/driver-profile/${params?.id}`,
    },
    {
      name: "Document",
      path: `/driver-document/${params?.id}`,
    },
    {
      name: "Account",
      path: `/driver-account/${params?.id}`,
    },
    {
      name: "Rating",
      path: `/driver-rating/${params?.id}`,
    },
    {
      name: "Transection History",
      path: `/driver-transection-history/${params?.id}`,
    },
  ];
  const [driverDetails, setDriverDetails] = useState(null);
  const getUserDetailsFunc = async () => {
    try {
      let response = await getDriverByIdServ(params.id);
      if (response?.data?.statusCode == "200") {
        setDriverDetails(response.data?.data);
      }
    } catch (error) {}
  };
  useEffect(() => {
    getUserDetailsFunc();
  }, []);
  const driverDocument = [
    {
      title: "Driving Licence",
      image: driverDetails?.licence_image,
      exp: driverDetails?.licence_expiry,
      key: "licence_status",
      expKey: "licence_expiry",
    },
    {
      title: "Ownership",
      image: driverDetails?.ownership_image,
      key: "ownership_status",
      expKey: "ownership_expiry",
    },
    {
      title: "Insurance",
      image: driverDetails?.insurance_image,
      exp: driverDetails?.insurance_expiry,
      key: "insurance_status",
      expKey: "insurance_expiry",
    },
    {
      title: "Direct Deposite Form",
      image: driverDetails?.licence_image,
    },
  ];
  const tableHeader = ["Sr.No", "Account", "Transection Details", "Date", "Time", "New Balance"];
  return (
    <div className="main_layout  bgBlack d-flex">
      {/* sidebar started */}
      <Sidebar selectedItem="Driver" />
      {/* sidebar ended */}

      {/* sectionLayout started */}
      <section className="section_layout" style={{ marginLeft :globalState?.isFillSidebarWidth100 ? "260px": "80px" }}>
        {/* top nav started  */}

        {/* top nav ended  */}
        {/* table List started */}
        <div className="">
          <TableNav tableNav={tableNav} selectedItem="Transection History" sectedItemBg="#FDEEE7" />
          <div className="tableBody py-2 px-4 borderRadius50All" style={{ background: "#FDEEE7" }}>
          <div className=" px-2 py-4 my-4" style={{ borderRadius: "20px", background: "#fff" }}>
              <div style={{ margin: "0px 10px" }}>
                <table className="table">
                  <thead>
                    <tr style={{ background: "#FDEEE7", }}>
                      <th scope="col" style={{ borderRadius: "8px 0px 0px 8px" }}>
                        Sr.No
                      </th>
                      <th scope="col">Amount</th>
                      <th scope="col">Transection Details</th>
                      <th scope="col">Date</th>
                      <th scope="col">Time</th>
                      <th scope="col" style={{ borderRadius: "0px 8px 8px 0px" }}>
                        New Balance
                      </th>
                    </tr>
                  </thead>
                  <div className="py-2"></div>
                  <tbody className="driverDocument">
                    {showSkelton
                      ? Array.from({ length: 5 }).map((_, index) => (
                          <tr key={index}>
                            <td>
                              <Skeleton width={50} />
                            </td>
                            <td>
                              <Skeleton   width={80} />
                            </td>
                            <td>
                              <Skeleton width={150} />
                            </td>
                            <td>
                              <Skeleton width={100} />
                            </td>
                            <td>
                              <Skeleton width={80} />
                            </td>

                            <td>
                              <Skeleton width={120} />
                            </td>
                          </tr>
                        ))
                      : <></>}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {/* table List ended */}
      </section>
      {/* sectionLayout ended */}

    
    </div>
  );
}

export default DriverTransectionHistory;
