import React, { useState, useEffect } from "react";
import Sidebar from "../../components/Sidebar";
import { getUserListServ } from "../../services/user.services";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useNavigate } from "react-router-dom";
import { useGlobalState } from "../../GlobalProvider";
function UserList() {
  const { setGlobalState, globalState } = useGlobalState();
  const navigate = useNavigate();
  const [userList, setUserList] = useState([]);
  const [showSkelton, setShowSkelton] = useState(false);
  const handleGetUserListFunc = async () => {
    setShowSkelton(true);
    try {
      let response = await getUserListServ();
      if (response?.data?.statusCode == "200") {
        setUserList(response?.data?.users);
      }
    } catch (error) {}
    setShowSkelton(false);
  };
  useEffect(() => {
    handleGetUserListFunc();
  }, []);

  const [userHistory, setUserHistory] = useState({
    show: false,
  });
  const [userFunds, setUserFunds] = useState({
    show: false,
  });
  return (
    <div className="main_layout  bgBlack d-flex">
      {/* sidebar started */}
      <Sidebar selectedItem="User" />
      {/* sidebar ended */}

      {/* sectionLayout started */}
      <section className="section_layout" style={{minWidth:"1350px", marginLeft :globalState?.isFillSidebarWidth100 ? "260px": "80px" }}>
        {/* table List started */}
        <div className="tableMain" style={{ marginTop: "0px" }}>
          <div className="tableBody   ">
            {/* upper section start */}
            <div className="row ms-2 mb-5 p-0">
              <div className="col-5 m-0 p-0">
                <div className="searchBox d-flex">
                  <img src="https://cdn-icons-png.flaticon.com/128/751/751463.png" />
                  <input placeholder="Search" />
                </div>
              </div>
              <div className="col-7 m-0 p-0 row">
                <div className="col-4">
                  <div className="filterBox d-flex ">
                    <img src="https://cdn-icons-png.flaticon.com/128/1159/1159641.png" />
                    <select>
                      <option>Filter</option>
                    </select>
                  </div>
                </div>
                <div className="col-2 my-auto">
                  <div className="d-flex justify-content-between showSelectDiv align-items-center">
                    <p className="mb-0">Show</p>
                    <select>
                      <option>10</option>
                    </select>
                  </div>
                </div>
                <div className="col-2  d-flex align-items-center justify-content-center my-auto">
                  <div className="justify-content-center align-items-center ">
                    <p className="mb-0 entriesText">Entiries</p>
                  </div>
                </div>
                <div className="col-4">
                  <div
                    onClick={() => navigate("/add-user")}
                    className="addUserBtn d-flex justify-content-between align-items-center"
                  >
                    <p className="mb-0">Add User</p>
                    <img src="https://cdn-icons-png.flaticon.com/128/1077/1077114.png" />
                  </div>
                </div>
              </div>
            </div>
            {/* upper section end */}
            <div style={{ margin: "0px 10px" }}>
              <table className="table">
                <thead>
                  <tr style={{ background: "#f9f9f9" }}>
                    <th scope="col" style={{ borderRadius: "8px 0px 0px 8px" }}>
                      <div className="d-flex justify-content-center ms-2">
                        <span>Sr. No</span>
                      </div>
                    </th>
                    <th scope="col">Profile Picture</th>
                    <th scope="col">First Name</th>
                    <th scope="col">Last Name</th>
                    <th scope="col">Email Address</th>
                    <th scope="col">Phone Number</th>
                    <th scope="col">Wallet</th>

                    <th scope="col">Status</th>
                    <th scope="col">Password</th>
                    <th scope="col">History</th>
                    <th scope="col">Funds</th>
                    <th scope="col" style={{ borderRadius: "0px 8px 8px 0px" }}>
                      Manage Profile
                    </th>
                  </tr>
                </thead>
                <div className="py-2"></div>
                <tbody>
                  {showSkelton
                    ? Array(10)
                        .fill()
                        .map((_, i) => (
                          <tr key={i}>
                            <td>
                              <Skeleton width={20} />
                            </td>
                            <td>
                              <Skeleton circle height={50} width={50} />
                            </td>
                            <td>
                              <Skeleton width={80} />
                            </td>
                            <td>
                              <Skeleton width={80} />
                            </td>
                            <td>
                              <Skeleton width={120} />
                            </td>
                            <td>
                              <Skeleton width={100} />
                            </td>
                            <td>
                              <Skeleton width={60} />
                            </td>
                            <td>
                              <Skeleton width={80} />
                            </td>
                            <td>
                              <Skeleton width={60} />
                            </td>
                            <td>
                              <Skeleton width={60} />
                            </td>
                            <td>
                              <Skeleton width={60} />
                            </td>
                            <td>
                              <Skeleton width={80} />
                            </td>
                          </tr>
                        ))
                    : userList?.map((v, i) => (
                        <tr key={i}>
                          <td scope="row">{i + 1}</td>
                          <td>
                            <img
                              src={
                                v?.image
                                  ? "https://api.faizah.in/storage/" + v?.image
                                  : "https://cdn-icons-png.flaticon.com/128/3177/3177440.png"
                              }
                              style={{ height: "50px", cursor: "pointer", marginTop: "-16px" }}
                            />
                          </td>
                          <td>{v?.first_name}</td>
                          <td>{v?.last_name}</td>
                          <td>{v?.email}</td>
                          <td>
                            {v?.country_code} {v?.contact}
                          </td>
                          <td>${v?.user_wallet}</td>
                          <td>
                            <select style={{ background: "#F9F9F9", color: "black" }}>
                              <option>Accept</option>
                              <option>Reject</option>
                            </select>
                          </td>
                          <td>{v?.password?.substring(0, 6)}</td>
                          <td>
                            <button
                              className="btn btn-primary"
                              style={{ marginTop: "-6px", background: "#7650E0", borderRadius: "6px", border: "none" }}
                              onClick={() => {
                                setUserHistory({
                                  show: true,
                                });
                              }}
                            >
                              View
                            </button>
                          </td>
                          <td>
                            <button
                              className="btn btn-primary"
                              style={{ marginTop: "-6px", background: "#7650E0", borderRadius: "6px", border: "none" }}
                              onClick={() => {
                                setUserFunds({
                                  show: true,
                                });
                              }}
                            >
                              Open
                            </button>
                          </td>
                          <td>
                            <select style={{ background: "#F9F9F9", marginTop: "-6px", color: "black" }}>
                              <option>Edit</option>
                              <option>Delete</option>
                            </select>
                          </td>
                        </tr>
                      ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {/* table List ended */}
      </section>
      {/* sectionLayout ended */}

      {/* User History Start */}
      {/* Modal */}
      {userHistory?.show && (
        <div className="modal fade show d-flex align-items-center justify-content-center userHistoryPopUp" tabIndex="-1">
          <div className="modal-dialog" style={{width:"475px"}}>
            <div
              className="modal-content"
              style={{ border: "1px solid #7650E0", borderRadius: "9px", background: "#f9fafc" }}
            >
              <div className="d-flex justify-content-end p-2">
                <i className="fa fa-close text-secondary" onClick={()=>{
                  setUserHistory({
                    show:false
                  })
                }}></i>
              </div>
              <h6 className="mb-4">History</h6>
              <div className="modal-body p-0">
                {[1, 2, 3, 4]?.map((v, i) => {
                  return (
                    <div className="row mx-0 mb-1 px-3 d-flex justify-content-between">
                      <div className="col-6 row">
                        <div className="col-5">
                          <p style={{color:"#139F01"}}>$50</p>
                        </div>
                        <div className="col-7">
                          <p>Bank Transfer</p>
                        </div>
                      </div>
                      <div className="col-6 row">
                        <div className="col-7">
                          <p>22 feb 2024</p>
                        </div>
                        <div className="col-5">
                          <p>07:17 pm</p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      )}
      {userHistory?.show && <div className="modal-backdrop fade show"></div>}
      {/* User History end*/}


      {/* User Funds Start */}
      {/* Modal */}
      {userFunds?.show && (
        <div className="modal fade show d-flex align-items-center justify-content-center userHistoryPopUp" tabIndex="-1">
          <div className="modal-dialog" style={{width:"370px"}}>
            <div
              className="modal-content"
              style={{ border: "1px solid #7650E0", borderRadius: "9px", background: "#f9fafc" }}
            >
              <div className="d-flex justify-content-end p-2">
                <i className="fa fa-close text-secondary" onClick={()=>{
                  setUserFunds({
                    show:false
                  })
                }}></i>
              </div>
              <h6 className="mb-4">Funds</h6>
              <div className="modal-body p-0">
              <div className="d-flex justify-content-center">
                <img src=""/>
              </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {userFunds?.show && <div className="modal-backdrop fade show"></div>}
      {/* User Funds end*/}
    </div>
  );
}

export default UserList;
