import React from "react";
import Sidebar from "../../components/Sidebar";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useGlobalState } from "../../GlobalProvider";
function MainDashboard() {
  const { setGlobalState, globalState } = useGlobalState();
  const staticsData = [
    {
      heading: "User",
      mainImg: "",
      count: "40",
      countColor: "#e52a2f",
      bottomImg: "",
      bottomText: "+15% from last month",
      bottomTextColor: "#139F01",
    },
    {
      heading: "Driver",
      mainImg: "",
      count: "40",
      countColor: "#154993",
      bottomImg: "",
      bottomText: "+15% from last month",
      bottomTextColor: "#139F01",
    },
    {
      heading: "Total  Monthly  Earning",
      mainImg: "",
      count: "$340",
      countColor: "#ec5e24",
      bottomImg: "",
      bottomText: "+15% from last month",
      bottomTextColor: "#139F01",
    },
    {
      heading: "My Monthly Earning",
      mainImg: "",
      count: "$250",
      countColor: "#154993",
      bottomImg: "",
      bottomText: "+15% from last month",
      bottomTextColor: "#139F01",
    },

    {
      heading: "Today's Interac E-Transfer",
      mainImg: "",
      count: "$40",
      countColor: "#ffc728",
      bottomImg: "",
      bottomText: "+15% from last month",
      bottomTextColor: "#139F01",
    },
    {
      heading: "Today's Quick Deposite",
      mainImg: "",
      count: "$10",
      countColor: "#4991e1",
      bottomImg: "",
      bottomText: "+15% from last month",
      bottomTextColor: "#139F01",
    },
  ];
  const handleLogoutFunc = () => {
    const confirmed = window.confirm("Are you sure you want to log out?");

    if (confirmed) {
      localStorage.removeItem("access_token");
      localStorage.removeItem("mie_ride_user");
      localStorage.removeItem("mie_ride_user_permissions");
      setGlobalState({
        access_token: null,
        user: null,
        permissions: null,
      });
      toast.success("Logged out successfully!");
    }
  };
  return (
    <div className="main_layout  bgBlack d-flex">

      {/* sidebar started */}
      <Sidebar selectedItem="Dashboard" />
      {/* sidebar ended */}

      {/* sectionLayout started */}
      <section className="section_layout" style={{ minWidth: "1400px" , marginLeft :globalState?.isFillSidebarWidth100 ? "260px": "80px" }}>
        <div className="d-flex justify-content-between align-items-center dashboardTopMain">
          <div className="d-flex align-items-end">
            <p className="mb-0 me-2">Welcome</p>
            <h6 className="mb-0">Sandeep Singh!</h6>
          </div>
          <div className="d-flex align-items-center">
            <select>
              <option>Ontairio</option>
            </select>
            <img src="/icons/dashboardIcons/userDummyIcon.png" style={{ height: "45px" }} className="mx-4" />
            <img src="/icons/dashboardIcons/logout.png" onClick={handleLogoutFunc} style={{ height: "27px" }} />
          </div>
        </div>
        <div className="row mx-0 my-4 p-0">
          {staticsData?.map((v, i) => {
            return (
              <div className="col-2 m-0 py-0 px-1">
                <div className="border p-3 p-mx-2 border staticCard">
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="mb-0">{v?.heading}</p>
                    <img src="/icons/dashboardIcons/user.png" style={{ height: "18px" }} />
                  </div>
                  <h5 className="my-3" style={{ color: v?.countColor }}>
                    {v?.count}
                  </h5>
                  <div className="d-flex  align-items-center">
                    <img src="/icons/dashboardIcons/greenProgress.png" style={{ height: "12px" }} />
                    <span className="mb-0 ms-1" style={{ color: v?.bottomTextColor }}>
                      15% from last month
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <div className="row m-0 p-0 mt-4">
          <div className="col-lg-5 p-0 m-0">
            <div className=" bookingGraph p-4 shadow">
              <div className="d-flex justify-content-between">
                <h6 className="mb-0">Booking Overview</h6>
                <div>
                  <select className="">
                    <option>Total</option>
                    <option>Completed</option>
                    <option>Cancelled</option>
                  </select>
                  <select className="">
                    <option>This Month</option>
                    <option>Todays</option>
                    <option>This year</option>
                    <option>Lifetime</option>
                  </select>
                </div>
              </div>
              <div className="row m-0 mt-4 p-0">
                <div className="col-1 m-0 p-0">
                  {[10, 9, 8, 7, 6, 5, 4, 3, 2, 1]?.map((v, i) => {
                    return (
                      <p
                        className="mb-0 text-end "
                        style={{
                          height: "33px",
                          position: "relative",
                          bottom: "5px",
                          fontSize: "12px",
                          fontWeight: "500",
                        }}
                      >
                        {v * 100}
                      </p>
                    );
                  })}
                  <p
                    className="mb-0 text-end "
                    style={{
                      height: "33px",
                      position: "relative",
                      bottom: "12px",
                      fontSize: "12px",
                      fontWeight: "500",
                    }}
                  >
                    0
                  </p>
                </div>
                <div className="col-11 row m-0 p-0">
                  {[1, 2, 4, 3, 5, 8, 9, 10, 11, 7, 8, 12]?.map((v, i) => {
                    return (
                      <div className="col-1 ">
                        <div
                          style={{
                            height: "330px",
                            width: "22px",
                            borderRadius: "8px",
                            background: "#F5F5F5",
                          }}
                          className="d-flex align-items-end"
                        >
                          <div
                            className=""
                            style={{
                              height: v * 10 + "px",
                              width: "22px",
                              borderRadius: "8px",
                              background: "#139F01",
                            }}
                          ></div>
                        </div>
                      </div>
                    );
                  })}
                  {["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]?.map((v, i) => {
                    return (
                      <div className="col-1 ">
                        <div style={{ width: "22px" }} className="d-flex align-items-end justify-content-center">
                          <p style={{ fontSize: "10px" }}>{v}</p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="row col-lg-7 p-0 m-0 ">
            <div className="col-lg-6 m-0 p-0 px-4">
              <div className="shadow-sm pieGraph  p-4">
                <div className="d-flex justify-content-between">
                  <h6 className="mb-0">Category Breakdown</h6>
                  <div>
                    <select className="shadow-sm me-2">
                      <option>Daily</option>
                      <option>Weekly</option>
                      <option>Cancelled</option>
                    </select>
                  </div>
                </div>
                <div className="d-flex justify-content-center align-items-center" style={{ height: "100%" }}>
                  <img className="img-fluid" src="/images/vector_smart_object.png" />
                </div>
              </div>
            </div>
            <div className="col-lg-6 m-0 p-0">
              <div className="shadow-sm tasksRemainer  p-4">
                <div className="d-flex  btnGroup">
                  <div className="toggleBtn activeToggle">Taks</div>
                  <div className="toggleBtn">Remainder</div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* sectionLayout ended */}
    </div>
  );
}

export default MainDashboard;
