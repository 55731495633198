import React, { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar";
import TopNav from "../../components/TopNav";
import TableNav from "../../components/TableNav";
import { getUserInteractETransferServ, updateUserInteracStatusServ } from "../../services/fundsManagement.services";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { toast } from "react-toastify";
import moment from "moment";
import { useGlobalState } from "../../GlobalProvider";
function UserInteracDeposite() {
  const { setGlobalState, globalState } = useGlobalState();
  const navItems = [
    {
      name: "User Deposite",
      path: "/user-interac-deposite",
    },
    {
      name: "Driver Withdraw",
      path: "/driver-weekly-withdraw",
    },
    {
      name: "Switch",
      path: "/funds-switch",
    },
   
    {
      name: "Cancel Response",
      path: "/funds-cancel-response",
    },
  ];
  const tableNav = [
    {
      name: "Interac E-Transfer",
      path: "/user-interac-deposite",
    },
    {
      name: "Quick Deposite",
      path: "/user-quick-deposite",
    },
    {
      name: "Email",
      path: "/integrated-email",
    },
  ];
  const [data, setData] = useState([]);
  const [showSkelton, setShowSkelton] = useState(false);
  const [staticsData, setStaticsData] = useState({
    total: "",
    monthly: "",
    today: "",
  });
  const getUserInteractETransferFund = async () => {
    if (data?.length == 0) {
      setShowSkelton(true);
    }
    setShowSkelton(true);
    try {
      let response = await getUserInteractETransferServ();
      if (response?.data?.statusCode == "200") {
        setStaticsData({
          total: parseInt(response?.data?.total_reject) + parseInt(response?.data?.total_accept),
          monthly: response?.data?.monthly_total,
          today: response?.data?.today_total,
        });
        console.log(response?.data);
        setData(response?.data?.data);
      }
    } catch (error) {}
    setShowSkelton(false);
  };
  useEffect(() => {
    getUserInteractETransferFund();
  }, []);
  const updateStatus = async (id, status) => {
    try {
      let response = await updateUserInteracStatusServ({ id: id, status: status });
      if (response?.data?.statusCode == "409") {
        toast.error(response?.data?.message);
      } else if (response?.data?.statusCode == "200") {
        toast.success(response?.data?.message);
        getUserInteractETransferFund();
      }
    } catch (error) {}
  };
  const [documentDetails, setDocumentDetails] = useState({
    show: false,
    image: "",
    first_name: "",
  });
  const downloadImage = async (url, filename) => {
    try {
      // Fetch the image as a Blob
      const response = await fetch(url);
      const blob = await response.blob();

      // Create a temporary link element
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = filename;

      // Programmatically click the link to trigger the download
      link.click();

      // Clean up the Object URL
      URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error("Image download failed:", error);
    }
  };
  return (
    <div className="main_layout  bgBlack d-flex">
      {/* sidebar started */}
      <Sidebar selectedItem="Funds Management" />
      {/* sidebar ended */}

      {/* sectionLayout started */}
      <section className="section_layout" style={{ marginLeft :globalState?.isFillSidebarWidth100 ? "260px": "80px" }}>
        {/* top nav started  */}
        <TopNav
          navItems={navItems}
          navColor="#030303"
          divideRowClass="col-xl-6 col-lg-6 col-md-12 col-12"
          selectedItem="User Deposite"
          sectedNavBg="#fff"
          selectedNavColor="#030303"
          navBg="#FD8917"
        />
        {/* top nav ended  */}
        {/* table List started */}
        <div className="tableMain">
          <TableNav tableNav={tableNav} selectedItem="Interac E-Transfer" sectedItemBg="#f2fbff" />
          <div className="tableBody py-2 px-4 borderRadius50exceptTopLeft" style={{ background: "#f2fbff" }}>
            <div className="row mx-0 p-0 justify-content-around marginY35">
              <div className="col-lg-4  m-0 ">
                <div className="d-flex justify-content-between align-items-center whiteBtn">
                  <p className="mb-0">Total Quick Deposite</p>
                  <p className="mb-0">${staticsData?.total}</p>
                </div>
              </div>
              <div className="col-lg-4 m-0">
                <div className="d-flex justify-content-between align-items-center whiteBtn">
                  <p className="mb-0">Monthly Quick Deposite</p>
                  <p className="mb-0">${staticsData?.monthly}</p>
                </div>
              </div>
              <div className="col-lg-4 m-0 ">
                <div className="d-flex justify-content-between align-items-center whiteBtn">
                  <p className="mb-0">Today's Quick Deposite</p>
                  <p className="mb-0">${staticsData?.today}</p>
                </div>
              </div>
            </div>
            <div style={{ margin: "0px 10px" }}>
              <table className="table">
                <thead>
                  <tr style={{ background: "#DCE4E7" }}>
                    <th scope="col" style={{ borderRadius: "8px 0px 0px 8px" }}>
                      Sr. No
                    </th>
                    <th scope="col">First Name</th>
                    <th scope="col">Last Name</th>
                    <th scope="col">Date</th>
                    <th scope="col">Time</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Status</th>
                    <th scope="col">Transection Proof</th>
                    <th scope="col" style={{ borderRadius: "0px 8px 8px 0px" }}>
                      Action
                    </th>
                  </tr>
                </thead>
                <div className="py-2"></div>
                <tbody>
                  {showSkelton
                    ? Array.from({ length: 5 }).map((_, index) => (
                        <tr key={index}>
                          <td>
                            <Skeleton width={20} />
                          </td>
                          <td>
                            <Skeleton width={80} />
                          </td>
                          <td>
                            <Skeleton width={80} />
                          </td>
                          <td>
                            <Skeleton width={100} />
                          </td>
                          <td>
                            <Skeleton width={80} />
                          </td>
                          <td>
                            <Skeleton width={60} />
                          </td>
                          <td>
                            <Skeleton width={60} />
                          </td>
                          <td>
                            <Skeleton width={40} height={20} />
                          </td>
                          <td>
                            <Skeleton width={80} />
                          </td>
                        </tr>
                      ))
                    : data?.data?.map((v, i) => (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>{v?.user?.first_name}</td>
                          <td>{v?.user?.last_name}</td>
                          <td>{moment(v.created_date).format("DD/MM/YYYY")}</td>
                          <td>{v?.created_time}</td>
                          <td>${v?.amount}</td>
                          <td>
                            {v?.status === "1" ? (
                              <span style={{ color: "#139F01" }}>Accept</span>
                            ) : v?.status === "0" ? (
                              <span style={{ color: "#FD8917" }}>Pending</span>
                            ) : (
                              <span style={{ color: "#FC3D3E" }}>Reject</span>
                            )}
                          </td>
                          <td>
                            <div className="d-flex justify-content-center iconDiv">
                              <img
                                onClick={() =>
                                  setDocumentDetails({
                                    img: v?.image,
                                    show: true,
                                    first_name: v?.user?.first_name,
                                  })
                                }
                                src="https://cdn-icons-png.flaticon.com/128/159/159604.png"
                                alt="proof"
                              />
                              <img
                                onClick={() => downloadImage(v?.image, `${v?.user?.first_name}'stransection_proof.png`)}
                                src="https://cdn-icons-png.flaticon.com/128/724/724933.png"
                                alt="download proof"
                              />
                            </div>
                          </td>
                          <td className="d-flex justify-content-center align-items-center">
                            <select value={v?.status} onChange={(e) => updateStatus(v?.id, e.target.value)}>
                              <option value="1">Accept</option>
                              <option value="-">Reject</option>
                              <option value="0">Pending</option>
                            </select>
                          </td>
                        </tr>
                      ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {/* table List ended */}
      </section>
      {/* sectionLayout ended */}

      {/* view transection socument popup start */}
      {/* Modal */}
      {documentDetails.show && (
        <div className="modal show d-block" tabIndex="-1">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Transection Proof of "{documentDetails?.first_name}"</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() =>
                    setDocumentDetails({
                      show: false,
                      img: "",
                    })
                  }
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <img src={documentDetails?.img} className="img-fluid" />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() =>
                    setDocumentDetails({
                      show: false,
                      img: "",
                    })
                  }
                >
                  Close
                </button>
                {/* <button type="button" className="btn btn-primary">
                  Save changes
                </button> */}
              </div>
            </div>
          </div>
        </div>
      )}
      {/* view transection socument popup end*/}
    </div>
  );
}

export default UserInteracDeposite;
