import React, { useEffect } from "react";
import { useGlobalState } from "./GlobalProvider";
import AuthenticatedRoutes from "./routes/AuthenticatedRoutes";
import UnAuthenticatedRoutes from "./routes/UnAuthenticatedRoutes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OneSignal from "react-onesignal";

function App() {
  useEffect(() => {
    // Ensure this code runs only on the client side
    if (typeof window !== "undefined") {
      OneSignal.init({
        appId: "84155339-356b-4c57-ae83-a3122bbb769d",
        // You can add other initialization options here
        serviceWorkerPath: "/public/OneSignalSDKWorker.js",
        notifyButton: {
          enable: true,
        },
        // Uncomment the below line to run on localhost. See: https://documentation.onesignal.com/docs/local-testing
        // allowLocalhostAsSecureOrigin: true
      });
    } else {
      alert("something went wrong");
    }
  }, []);

  // Access global state (assuming globalState has user)
  const { globalState } = useGlobalState();

  // Conditional rendering based on user authentication status
  const renderLayout = () => {
    if (globalState?.user) {
      return <AuthenticatedRoutes />;
    } else {
      return <UnAuthenticatedRoutes />;
    }
  };

  return (
    <>
      {renderLayout()}
      <ToastContainer />
    </>
  );
}

export default App;
