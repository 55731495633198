import React from "react";
import { useNavigate , useParams} from "react-router-dom";
function DriverSideNav({ selectedNav }) {
  const params = useParams();
  const navigate = useNavigate();
  const navItems = [
    {
      name: "Personal Details",
      path: "/driver-personal-details-verification",
    },
    {
      name: "Car Details",
      path: "/driver-car-details-verification",
    },
    {
      name: "Profile Photo",
      path: "/driver-profile-details-verification",
    },
    {
      name: "Driving License",
      path: "/driver-license-details-verification",
    },
    {
      name: "Ownership",
      path: "/driver-ownership-details-verification",
    },
    {
      name: "Insurance",
      path: "/driver-insurance-details-verification",
    },
    {
      name: "Review & Approve",
      path: "/driver-review-details-verification",
    },
  ];
  return (
    <div className="driverHorizontalNav">
      {navItems?.map((v, i) => {
        return (
          <div className="driverPopUpBtn" style={{ background: selectedNav==v?.name ? "#FFF0E3" :"#fff" }}>
            <button onClick={() => navigate(v?.path+"/"+params?.id)} style={{ background: selectedNav==v?.name ? "#000":"#139F01" }}>
              {v?.name}
            </button>
          </div>
        );
      })}
    </div>
  );
}

export default DriverSideNav;
