import React, { useState, useEffect } from "react";
import Sidebar from "../../components/Sidebar";
import { getDriverListServ } from "../../services/driver.services";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useNavigate } from "react-router-dom";
import { useGlobalState } from "../../GlobalProvider";
function DriverList() {
  const { setGlobalState, globalState } = useGlobalState();
  const navigate = useNavigate();
  const [driverList, setDriverList] = useState([]);
  const [showSkelton, setShowSkelton] = useState(false);
  const [payload, setPayload] = useState({
    page: 1,
    search_key: "",
    per_page: 10,
  });
  const [pageData, setPageData]=useState({
    total_pages:"",
    current_page:""
  })
  const renderPage = () => {
    const pages = [];
    
    // Generate page numbers
    for (let i = 1; i <= pageData?.total_pages; i++) {
      pages.push(
        <li key={i} className={`page-item ${i === pageData?.current_page ? "active " : ""}`}>
          <a className="page-link" href="#">
            {i}
          </a>
        </li>
      );
    }

    return (
      <nav aria-label="Page navigation example">
        <ul className="pagination justify-content-center">
          {/* Previous button */}
          <li className={`page-item ${pageData?.current_page === 1 ? " disabled" : ""}`}>
            <a className="page-link" href="#" aria-label="Previous">
              <span aria-hidden="true">«</span>
            </a>
          </li>

          {/* Page numbers */}
          {pages}

          {/* Next button */}
          <li className={`page-item ${pageData?.current_page === pageData?.total_pages ? "disabled" : ""}`}>
            <a className="page-link" href="#" aria-label="Next">
              <span aria-hidden="true">»</span>
            </a>
          </li>
        </ul>
      </nav>
    );
  };
  const handleGetUserListFunc = async () => {
    if (driverList?.length == 0) {
      setShowSkelton(true);
    }
    try {
      let response = await getDriverListServ(payload);
      if (response?.data?.statusCode == "200") {
        setDriverList(response?.data?.data);
        setPageData({
          total_pages:response?.data?.total_pages,
          currect_page:response?.data?.data?.current_page
        })
      }
    } catch (error) {}
    setShowSkelton(false);
  };
  useEffect(() => {
    handleGetUserListFunc();
    renderPage()
  }, [payload]);

  return (
    <div className="main_layout  bgBlack d-flex">
      {/* sidebar started */}
      <Sidebar selectedItem="Driver" />
      {/* sidebar ended */}

      {/* sectionLayout started */}
      <section className="section_layout" style={{minWidth:"1550px", marginLeft :globalState?.isFillSidebarWidth100 ? "260px": "80px" }}>
        {/* table List started */}
        <div className="tableMain" style={{ marginTop: "0px" }}>
          <div className="tableBody   ">
            {/* upper section start */}
            <div className="row ms-2 mb-5 p-0">
              <div className="col-5 m-0 p-0">
                <div className="searchBox d-flex">
                  <img src="https://cdn-icons-png.flaticon.com/128/751/751463.png" />
                  <input
                    placeholder="Search"
                    onChange={(e) => setPayload({ ...payload, search_key: e.target.value })}
                  />
                </div>
              </div>
              <div className="col-7 m-0 p-0 row">
                <div className="col-4">
                  <div className="filterBox d-flex ">
                    <img src="https://cdn-icons-png.flaticon.com/128/1159/1159641.png" />
                    <select>
                      <option>Filter</option>
                    </select>
                  </div>
                </div>
                <div className="col-2 my-auto">
                  <div className="d-flex justify-content-between showSelectDiv align-items-center">
                    <p className="mb-0">Show</p>
                    <select
                      value={payload.per_page}
                      onChange={(e) => setPayload({ ...payload, per_page: e.target.value })}
                    >
                      <option value="10">10</option>
                      <option value="15">15</option>
                      <option value="20">20</option>
                      <option value="25">25</option>
                    </select>
                  </div>
                </div>
                <div className="col-2  d-flex align-items-center justify-content-center my-auto">
                  <div className="justify-content-center align-items-center ">
                    <p className="mb-0 entriesText">Entiries</p>
                  </div>
                </div>
                <div className="col-4">
                  <div
                    onClick={() => navigate("/add-driver")}
                    className="addUserBtn d-flex justify-content-between align-items-center"
                    style={{ background: "#EC5C13", cursor: "pointer" }}
                  >
                    <p className="mb-0">Add Driver</p>
                    <img src="/icons/sidebarIcons/driver.png" />
                  </div>
                </div>
              </div>
            </div>
            {/* upper section end */}
            <div style={{ margin: "0px 10px" }}>
              <table className="table">
                <thead>
                  <tr style={{ background: "#EC5C13", color: "white" }}>
                    <th scope="col" style={{ borderRadius: "8px 0px 0px 8px" }}>
                      <div className="d-flex justify-content-center ms-2">
                        <span>Sr. No</span>
                      </div>
                    </th>
                    <th scope="col">Profile Picture</th>
                    <th scope="col">First Name</th>
                    <th scope="col">Last Name</th>
                    <th scope="col">Email Address</th>
                    <th scope="col">Phone Number</th>
                    <th scope="col">Wallet</th>
                    <th scope="col">Vehicle Brand</th>
                    <th scope="col">Vehicle Colour</th>
                    <th scope="col">Year Of Manufacture</th>
                    <th scope="col">Vehicle Size</th>
                    <th scope="col">Status</th>

                    <th scope="col" style={{ borderRadius: "0px 8px 8px 0px" }}>
                      <div className="d-flex justify-content-center me-3">
                        <span>Profile</span>
                      </div>
                    </th>
                  </tr>
                </thead>
                <div className="py-2"></div>
                <tbody className="driverTable">
                  {showSkelton
                    ? Array.from({ length: 5 }).map((_, i) => (
                        <tr key={i}>
                          <td>
                            <Skeleton width={40} />
                          </td>
                          <td>
                            <Skeleton circle={true} height={50} width={50} />
                          </td>
                          <td>
                            <Skeleton width={100} />
                          </td>
                          <td>
                            <Skeleton width={100} />
                          </td>
                          <td>
                            <Skeleton width={150} />
                          </td>
                          <td>
                            <Skeleton width={120} />
                          </td>
                          <td>
                            <Skeleton width={80} />
                          </td>
                          <td>
                            <Skeleton width={100} />
                          </td>
                          <td>
                            <Skeleton width={100} />
                          </td>
                          <td>
                            <Skeleton width={120} />
                          </td>
                          <td>
                            <Skeleton width={90} />
                          </td>
                          <td>
                            <Skeleton width={100} />
                          </td>
                          <td>
                            <Skeleton width={100} />
                          </td>
                        </tr>
                      ))
                    : driverList?.map((v, i) => (
                        <tr
                          key={i}
                          style={{ background: i % 2 === 0 ? "#FDEEE7" : "#fff", cursor: "pointer" }}
                          onClick={() =>
                            navigate(
                              v?.status == "Disapprove"
                                ? `/driver-personal-details-verification/${v?.id}`
                                : `/driver-profile/${v?.id}`
                            )
                          }
                        >
                          <td style={{ borderRadius: "12px 0px 0px 12px" }}>{i + 1}</td>
                          <td>
                            <img
                              src={
                                v?.image
                                  ? "https://api.faizah.in/storage/" + v?.image
                                  : "https://cdn-icons-png.flaticon.com/128/3177/3177440.png"
                              }
                              alt="Profile"
                              style={{ height: "50px", borderRadius: "50%", width: "50px", marginTop: "-13px" }}
                            />
                          </td>
                          <td>{v?.first_name}</td>
                          <td>{v?.last_name}</td>
                          <td>{v?.email}</td>
                          <td>
                            {v?.country_code} {v?.contact}
                          </td>
                          <td>${v?.wallet_balance}</td>
                          <td>{v?.vehicle_name}</td>
                          <td>{v?.vehicle_colour}</td>
                          <td>{v?.vehicle_date}</td>
                          <td>{v?.vehicle_size} seater</td>
                          <td>
                            {v?.status === "Disapprove" ? (
                              <button
                                className="p-2"
                                style={{
                                  background: "#024596",
                                  fontSize: "12px",
                                  color: "#fff",
                                  border: "none",
                                  width: "100px",
                                  borderRadius: "7px",
                                  marginTop: "-6px",
                                }}
                              >
                                New Request
                              </button>
                            ) : (
                              <button
                                className="p-2"
                                style={{
                                  background: "#139F01",
                                  fontSize: "12px",
                                  color: "#fff",
                                  border: "none",
                                  width: "100px",
                                  borderRadius: "7px",
                                  marginTop: "-6px",
                                }}
                              >
                                Approved
                              </button>
                            )}
                          </td>
                          <td style={{ borderRadius: "0px 12px 12px 0px" }}>
                            <img src="/icons/sidebarIcons/driver.png" style={{ height: "18px" }} />
                          </td>
                        </tr>
                      ))}
                </tbody>
              </table>
              {renderPage()}
            </div>
          </div>
        </div>
        {/* table List ended */}
      </section>
      {/* sectionLayout ended */}
    </div>
  );
}

export default DriverList;
