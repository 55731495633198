import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGlobalState } from "../GlobalProvider";
function Sidebar({selectedItem}) {
  const { globalState, setGlobalState } = useGlobalState();
  const navigate = useNavigate();
  
  const navItems = [
    {
      title: "Dashboard",
      img: "/icons/sidebarIcons/dashboard.png",
      path: "/",
    },
    {
      title: "Command Center",
      img: "/icons/sidebarIcons/commandCenter.png",
      path: "/create-role",
      
    },
    {
      title: "Booking Dashboard",
      img: "/icons/sidebarIcons/appointment.png",
      path: "/sharing-new-booking",
    },
    {
      title: "Chat Support",
      img: "/icons/sidebarIcons/liveChat.png",
      path: "/chat-support",
    },
    {
      title: "Funds Management",
      img: "/icons/sidebarIcons/fundsBag.png",
      path: "/user-interac-deposite",
    },

    {
      title: "Ad Control Panel",
      img: "/icons/sidebarIcons/addControl.png",
      path: "/ads-user-panel",
    },
    {
      title: "Pricing & Cities",
      img: "/icons/sidebarIcons/pricingAndCity.png",
      path: "/pricing-categories",
    },
    {
      title: "User",
      img: "/icons/sidebarIcons/user.png",
      path: "/user-list",
    },
    {
      title: "Driver",
      img: "/icons/sidebarIcons/driver.png",
      path: "/driver-list",
    },
    {
      title: "Reports",
      img: "/icons/sidebarIcons/googleDoc.png",
      path: "/report",
    },
    // {
    //   title: "Commission",
    //   img: "/icons/sidebarIcons/commission.png",
    //   path: "/commission",
    // },
    {
      title: "Support",
      img: "/icons/sidebarIcons/customerSupport.png",
      path: "/support-faq-user",
    },
    
  ];

  return (
    <div className={globalState?.isFillSidebarWidth100? " sidebarMain" :" miniSideBar"}>
      <div className={" d-flex  mb-2  w-100 " + (globalState?.isFillSidebarWidth100 ? " justify-content-end":" justify-content-center")}>
        <i className="fa fa-bars text-light" onClick={()=>setGlobalState({
          ...globalState, isFillSidebarWidth100 : !globalState.isFillSidebarWidth100
        })}></i>
      </div>
      <div className="d-flex justify-content-center align-items-center  logoGroup">
        <img src="/icons/sidebarIcons/brandLogo.png" className="mx-2" style={{height:globalState?.isFillSidebarWidth100 ? " 100px":" 60px", marginBottom:globalState?.isFillSidebarWidth100 ? " 0px":" -35px",}}/>
        {globalState?.isFillSidebarWidth100 && <img src="/icons/sidebarIcons/mie_ride.png" className="mx-2" />}
        
      </div>
      <div className="navItemsContainer">
        {navItems?.map((v, i) => {
          return (
            <div onClick={()=>navigate(v?.path)} className="d-flex align-items-center sideNavItem" style={{background: selectedItem==v?.title ? " #139F01":""}}>
              <img src={v?.img} />
              {globalState?.isFillSidebarWidth100 && <p className="mb-0">{v?.title}</p>}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Sidebar;
